import axios from 'axios';
import CitizenInvitationCdo from '../../../comp/api/pavillion/api-model/sdo/CitizenInvitationCdo';
import { apiURL } from '..';

export const metroURL = apiURL+'/api/metro';
const secureFlowUrl = '/secure/extraordinary-key-flow/query';
const tenantFlowUrl = '/flow/tenant-flow';
const aggregateCitiInviUrl = '/aggregate/citizenInvitation';
export const flowSignUpURL = '/flow/sign-up-query-flow';

export enum ExtraordinaryType {
  TOP_CINEROOM = 'TOP_CINEROOM',   //  cineroom with audience from tour one pass oauth
  TOP_CITIZEN = 'TOP_CITIZEN',   //  citizens from tour one pass oauth
  KTO_CINEROOM = 'KTO_CINEROOM',    //  cineroom with audience in kto
  DCC_CINEROOM = 'DCC_CINEROOM',    // Digital Content Center
  IN_PROGRESS_REQUESTER_EMAIL = 'IN_PROGRESS_REQUESTER_EMAIL'    // Held Email Of Cineroom Requester, this must be included for email dupl checking for servant
}

export const metroFlowSignUpPostFunction = async (requestUrl: string, data: any) => {
  const result = await axios.post(`${metroURL+flowSignUpURL+requestUrl}`, data);
  return result.data;
}

export const findDCCCineroom = async (pavilionId: string, extraordinaryType: ExtraordinaryType,) => {
  const data = {
    pavilionId,
    extraordinaryType
  }
  
  const result = await axios.post(metroURL+secureFlowUrl+'/find-unique-extraordinary-cineroom', data);
    
  return result;  
}

export const addOrgCitizen = async (citizenInvitationCdo: CitizenInvitationCdo, optionalCineroomId: string, code: string, emailKey: string) => {
  const data = {
    citizenInvitationCdo,
    optionalCineroomId,
    code,
    emailKey
  }
  
  const result = await axios.post(metroURL+tenantFlowUrl+'/member/sign-up-origin-org-citizen', data);
    
  return result.data;  
}

export const findAudience = async (citizenId: string) => {
  const data = {
    citizenId
  }
  const res = await axios.post(metroURL+'/flow/tenant-context-flow/query/current-audience', data);
  return res.data;
}

export const modifyLatestCineroom = async (citizenId: string, latestCineroomId: string) => {
  const data = {
    citizenId,
    latestCineroomId
  }
  const res = await axios.post(metroURL+'/flow/tenant-context-flow'+'/modify-latest-cineroom', data);
  return res.data;
}

export const findCitizenInvitationBySsoId = async (ssoId: string) => {
  const data = {
    ssoId
  }
  const res = await axios.post(metroURL+aggregateCitiInviUrl+'/find-citizen-invitation-id/by-ssoId', data);
  return res.data.commandResponse.entityIds[0];
}

export const findCineroomById = async (cineroomId: string) => {
  const data = {
    cineroomId
  }
  const res = await axios.post(metroURL+'/aggregate/cineroom/cineroom/query/', data);
  return res.data.queryResult;
}