import React, { ContextType, createRef } from "react";
import "../css/common.css";
import "../css/swiper-bundle.min.css";
import moment from "moment";
import axios from "axios";
import ModalView from "./main-theme/ModalView";
import CardBannerView from "./main-theme/CardBannerView";
import CountUp from "react-countup";
import {
  toAudience,
} from "../shared/Function/checkpoint/Function";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, Autoplay } from "swiper/modules";
import SwiperCore from "swiper";
import CommonContext from "../context/CommonContext";
import LoginPannel from "../components/LoginPannel";
import Alert from "../components/Alert";
import { Chip } from "@mui/material";
import NoticeModalView from "../components/NoticeModalView";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from "react-slick";

interface Props {
  eventFetchDockNodes: (audienceId: string) => void;
  basePath: string;
}

interface State {
  listBannerObject: {
    id: string;
    title: string;
    subTitle: string;
    type: string;
    seq: number;
    moreLink: string;
    moreLinkYn: string;
    cid: string;
    categoryId: string;
    description: string;
  }[];
  openDetailModal: undefined | "detail";
  openThemeModal: undefined | "detail";
  modalType: string;
  categoryId: string;
  themeDetailList: object[];
  themeDetailObject: {
    actorId: string;
    audienceId: string;
    bannerImgPath: string;
    children: string;
    cineroomId: string;
    cineroomName: string;
    citizenId: string;
    cmsCategoryId: string;
    elementTypeId: string;
    entityVersion: string;
    existChild: boolean;
    folderType: boolean;
    id: string;
    idPaths: [];
    imagePath: string;
    langUsed: boolean;
    lastUpdateTime: number;
    modificationTime: number;
    lastUpdater: { id: string; name: string };
    marketingType: string;
    name: string;
    registrationTime: number;
    type: string;
    used: boolean;
    writer: { id: string; name: string };
    pavilionId: string;
    parentId: string;
    originCategoryId: string;
    originFolderId: string;
    seq: number;
    publicType: boolean;
  };
  recommendThemeObject: object[];
  imageDetailBase: {
    copyright: string;
    imageProperty: string;
    provider: string;
    depotId: string;
    imagePath: string;
    enTitle: string;
    cid: string;
    tid: string;
  };
  categoryPath: [];
  categoryAddress: {};
  noticeList: [];
  faqList: [];
  open: undefined | "open";
  selectedPostId: string;
  chartData: [];
  changeTotalCardData: { title: string; value: number }[];
  countUpData: { title: string; value: number }[];
  isAlert: boolean;
  alertMsg: string;
  openNotice: boolean;
  isPlay: boolean;
  btnHover: boolean[];
}

class MainPage extends React.Component<Props, State> {
  static defaultProps: any = {};
  static contextType = CommonContext;
  private audienceStorageKey = "nara.audience";
  private cineroomStorageKey = "nara.cineroom";

  bannerSwiper = createRef<SwiperRef>();

  domain = window.location.host;
  context!: ContextType<typeof CommonContext>;

  constructor(props: any) {
    super(props);
    this.onClickDetailModal = this.onClickDetailModal.bind(this);
    this.onClickCloseModal = this.onClickCloseModal.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.showNoticeDetail = this.showNoticeDetail.bind(this);
    this.getAudienceId = this.getAudienceId.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.openAlert = this.openAlert.bind(this);
    this.onClickThemeCloseModal = this.onClickThemeCloseModal.bind(this);
    this.closeNotice = this.closeNotice.bind(this);
    this.routeDev = this.routeDev.bind(this);
    this.mouseHoverBtn = this.mouseHoverBtn.bind(this);
    this.mouseHoverOutBtn = this.mouseHoverOutBtn.bind(this);
    this.bannerSwiper = React.createRef();
  }

  state: State = {
    listBannerObject: [],
    openDetailModal: undefined,
    openThemeModal: undefined,
    themeDetailList: [],
    categoryId: "",
    modalType: "",
    themeDetailObject: {
      actorId: "",
      audienceId: "",
      bannerImgPath: "",
      children: "",
      cineroomId: "",
      cineroomName: "",
      citizenId: "",
      cmsCategoryId: "",
      elementTypeId: "",
      entityVersion: "",
      existChild: false,
      folderType: false,
      id: "",
      idPaths: [],
      imagePath: "",
      langUsed: false,
      lastUpdateTime: 0,
      modificationTime: 0,
      lastUpdater: { id: "", name: "" },
      marketingType: "",
      name: "",
      registrationTime: 0,
      type: "",
      used: false,
      writer: { id: "", name: "" },
      pavilionId: "",
      parentId: "",
      originCategoryId: "",
      originFolderId: "",
      seq: 0,
      publicType: false,
    },
    recommendThemeObject: [],
    imageDetailBase: {
      copyright: "",
      imageProperty: "",
      provider: "",
      depotId: "",
      imagePath: "",
      enTitle: "",
      cid: "",
      tid: "",
    },
    categoryPath: [],
    categoryAddress: {},
    noticeList: [],
    faqList: [],
    open: undefined,
    selectedPostId: "",
    chartData: [],
    changeTotalCardData: [
      {
        title: "전체 콘텐츠",
        value: 0,
      },
      {
        title: "관광정보",
        value: 0,
      },
      {
        title: "이미지",
        value: 0,
      },
      {
        title: "동영상",
        value: 0,
      },
      {
        title: "자료",
        value: 0,
      },
    ],
    countUpData: [
      {
        title: "전체 콘텐츠",
        value: 0,
      },
      {
        title: "관광정보",
        value: 0,
      },
      {
        title: "이미지",
        value: 0,
      },
      {
        title: "동영상",
        value: 0,
      },
      {
        title: "자료",
        value: 0,
      },
    ],
    isAlert: false,
    alertMsg: '',
    openNotice: true,
    isPlay: true,
    btnHover: [false, false, false, false],
  };



  componentDidMount() {
    // document.body.style.cssText = "overflow: scroll";
    this.getListBanner();
    this.getFaqList();
    this.getNoticeList();
    this.contentTotalView();
    setInterval(() => {
      this.rerollCountup();
    }, 60000);
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void { }

  openAlert(alertMsg: string) {
    this.setState({ isAlert: true, alertMsg });
  }

  closeAlert() {
    this.setState({ isAlert: false });
  }

  closeNotice() {
    this.setState({ openNotice: false });
  }

  async getThemeDetail(category_id: string) {
    try {
      const response = await axios
        .post("/api/data/flow/4592b5e8-9db0-4719-ada3-ffe812093317", {
          responseName: "response",
          categoryId: category_id,
        })
        .then((res) => {
          this.setState({ themeDetailObject: res.data.queryResult });
        });
    } catch (e) {
      console.error(e);
    }
  }

  async getThemeDetailList(category_id: string) {
    try {
      const instance = axios.create();
      await instance
        .post(
          "/api/data/flow/c736c5e9-8dd9-4e1f-a9a6-542f29e02017",
          {
            responseName: "response",
            categoryId: category_id,
          },
          {}
        )
        .then((res) => {
          this.setState({ themeDetailList: res.data.queryResult });
        });
    } catch (e) {
      console.error(e);
    }
  }

  async getListBanner() {
    try {
      const response = await axios.get(
        "/api/naomi/flow/banner-flow/query/find-list-banner",
        {}
      );

      this.setState({ listBannerObject: response.data.queryResult });
    } catch (e) {
      console.error(e);
    }
  }

  async contentTotalView() {
    try {
      const response = await axios.get(
        "/api/naomi/v2/statistics/content-total-card-view",
        {}
      );
      this.setState({
        chartData: response.data.queryResult,
        countUpData: response.data.queryResult,
      });
    } catch (e) {
      console.error(e);
    }
  }

  rerollCountup() {
    this.setState({ countUpData: this.state.changeTotalCardData }, () => {
      this.setState({ countUpData: this.state.chartData });
    });
  }

  onClickCloseModal() {
    this.setState({ openDetailModal: undefined });
    document.body.style.cssText = "overflow: scroll";
  }

  onClickThemeCloseModal() {
    this.setState({ openThemeModal: undefined });
    document.body.style.cssText = "overflow: scroll";
  }

  onClickDetailModal(modalType: string, category_id: string) {
    this.setState({
      openDetailModal: "detail",
      modalType: modalType,
      categoryId: category_id,
    });
    document.body.style.cssText = "overflow: hidden";
    switch (modalType) {
      case "theme":
        this.getThemeDetail(category_id);
        this.getThemeDetailList(category_id);
        break;
    }
  }

  renderListBanner() {
    const { listBannerObject } = this.state;

    const listBanner = listBannerObject?.map((obj: any, i: any) => {
      let tempContents: any;

      if (i !== 0) {
        return null;
      }

      tempContents = (
        <CardBannerView
          key={obj.id}
          cardType={obj.type}
          listBannerObject={listBannerObject[i]}
          onClickDetailModal={this.onClickDetailModal}
          noTitle={true}
          boxInfo={true}
        />
      );
      return tempContents;
    });

    return <>{listBanner}</>;
  }

  showNoticeDetail(postId: string, startingTime: string, registrationTime: string) {
    const { selectedPostId } = this.state;
    let searchUrl = "/rms-conlab/announcement-detail?";

    searchUrl += "selectedPostId=" + encodeURIComponent(postId) + "&startingTime=" + encodeURIComponent(startingTime) + "&registrationTime=" + encodeURIComponent(registrationTime);

    window.location.href = searchUrl

  }

  showFaqDetail() {
    let searchUrl = "/rms-conlab/questions";
    window.location.href = searchUrl;
  }

  async getFaqList() {
    await axios
      .post("/api/faq/aggregate/faq/faq/query/dynamic-multi", {
        responseName: "response",
        queryParams: {
          queryParams: [
            {
              fieldName: "exhibited",
              operation: "Equal",
              value: "Exhibited",
              connector: "And",
            },
            {
              fieldName: "portalScope",
              operation: "Equal",
              value: "true",
              connector: "And",
            },
            {
              fieldName: "id",
              operation: "Like",
              value: "%",
              connector: "End",
            },
          ],
        },
        offset: {
          offset: 0,
          limit: 3,
          totalCount: 0,
          totalCountRequested: true,
          sortDirection: "DESCENDING",
          sortingField: "registrationTime",
        },
      })
      .then((res) => {
        const faqList = res.data.queryResult; // faqList를 여기서 설정
        this.setState({ faqList });
      });
  }

  async getNoticeList() {
    await axios.post('/api/board/flow/posts-flow/default-posts-title', {
      responseName: "response",
      queryParams: null,
      offset: {
        offset: 0,
        limit: 3,
        totalCount: 5,
        totalCountRequested: true,
        sortDirection: "DESCENDING",
        sortingField: "registrationTime"
      },
      role: "nonMember",
      manageable: false,
      scope: "portalScope",
      boardType: "NOTICE-BOARD",
    })
      .then((res) => {
        const noticeList = res.data.queryResult.posts;
        this.setState({ noticeList });
      });
  }

  close() {
    this.setState({ open: undefined });
    document.body.style.cssText = "overflow: unset";
  }

  open() {
    this.setState({ open: "open" });
    document.body.style.cssText = "overflow: hidden !important";
  }

  routeWorkspace() {
    const { openLoginModal, isLogin } = this.context;
    if (!isLogin) {
      openLoginModal();
      return;
    }

    window.open(`https://${this.domain}/rms`);
  }

  getAudienceId() {
    let retAudience: {
      id: string,
      name: string,
      usid: string
    } = toAudience('');

    // @ts-ignore
    const audienceId = JSON.parse(window.localStorage.getItem(this.audienceStorageKey));
    if (audienceId) {
      if (Object.keys(audienceId).length > 0) {
        retAudience = audienceId;
      }
    }

    return retAudience.name;
  }

  isPlayStopSwiper() {
    this.setState({ isPlay: false })
  }

  isPlayStartSwiper() {
    this.setState({ isPlay: true })
  }

  routeSearchK() {
    window.location.href = "/rms-conlab/search?keyword=%EA%B2%BD%EB%B3%B5%EA%B6%81&lang=ko&marketingType=ALL";
  }

  routeTourismRecommend() {
    window.location.href = "/rms-conlab/tourism-recommend";
  }

  routeDev() {
    const { openLoginModal, isLogin } = this.context;

    if (!isLogin) {
      openLoginModal();
      return;
    }

    // @ts-ignore
    const cineroomData = window.localStorage.getItem(this.cineroomStorageKey);
    if (!cineroomData) {
      this.openAlert("개발자 센터는 기업회원 전용 서비스입니다.");
      return;
    }
    const cineroom = JSON.parse(cineroomData);
    if (!cineroom.id || cineroom.id === '1:1:a') {
      this.openAlert("개발자 센터는 기업회원 전용 서비스입니다.");
      return;
    }

    // 오픈 전
    // this.openAlert("개발자 센터는 추후 오픈 예정입니다.");

    // 오픈 후
    window.open(`https://conlab.visitkorea.or.kr/developers/`);
  }

  mouseHoverBtn(index: number) {
    const newList = Array.from({ length: 4 }, (_, i) => i === index);
    this.setState({ btnHover: newList });
  }

  mouseHoverOutBtn() {
    this.setState({ btnHover: [false, false, false, false] });
  }

  renderOrganization() {

    SwiperCore.use([Navigation, Pagination, Autoplay]);

    const { isPlay } = this.state

    const start = () => {
      if (this.bannerSwiper.current) this.bannerSwiper.current.swiper.autoplay.start();
    }

    const stop = () => {
      if (this.bannerSwiper.current) this.bannerSwiper.current.swiper.autoplay.stop();
    }

    return (
      <div className="home_link_r">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar]}
          ref={this.bannerSwiper}
          pagination={{ clickable: true }}
          className="home_link_slide"
          loop={true}
          spaceBetween={0}
          slidesPerView={1}
          autoplay={{ delay: 1500, disableOnInteraction: false }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
        >
          {this.renderOrganizationInfo()}
          <div className="pagination_box">

            {isPlay === true ?
              <button className="auto_slide_bt">
                <img className="stop_img" src="rms-conlab/images/icon_pause.svg" alt="icon_pause" onClick={() => { stop(); this.isPlayStopSwiper() }} />
              </button> : null
            }

            {isPlay === false ?
              <button className="auto_slide_bt">
                <img className="play_img" src="rms-conlab/images/icon_play_circle.svg" alt="icon_play_circle" onClick={() => { start(); this.isPlayStartSwiper() }} />
              </button> : null
            }

          </div>
        </Swiper>
      </div>
    )
  }

  renderOrganizationInfo() {
    const orgMember = {
      "list": [
        {
          "title1": "한국관광공사",
          "title2": "",
          "description1": "여행하기 좋은 나라를 만드는 글로벌 관광선도기관",
          "description2": "",
          "image": "home_link_r_bg01",
          "link": "https://knto.or.kr/index"
        },
        {
          "title1": "대한민국",
          "title2": "구석구석",
          "description1": "한국관광공사가 운영하는 국내 여행 정보 서비스",
          "description2": "",
          "image": "home_link_r_bg02",
          "link": "https://korean.visitkorea.or.kr/main/main.do"
        },
        {
          "title1": "한국관광",
          "title2": "데이터랩",
          "description1": "기업, 지역, 국민이 실질적으로 체감하는",
          "description2": "관광 빅데이터 서비스 제공",
          "image": "home_link_r_bg03",
          "link": "https://datalab.visitkorea.or.kr/datalab/portal/main/getMainForm.do"
        },
        {
          "title1": "VISIT KOREA",
          "title2": "",
          "description1": "한국관광공사가 운영하는",
          "description2": "다국어 국내 여행 정보 서비스",
          "image": "home_link_r_bg04",
          "link": "https://english.visitkorea.or.kr/svc/main/index.do"
        },
        {
          "title1": "투어라즈",
          "title2": "",
          "description1": "한국관광산업에 필요한 A부터 Z까지!",
          "description2": "관광산업 통합플랫폼",
          "image": "home_link_r_bg05",
          "link": "https://touraz.kr/index"
        },
      ],
    }

    const orgLink = orgMember.list?.map((obj: any, i: any) =>
      <SwiperSlide key={i}>
        <div className="home_link_slide swiper">
          {/* <div className="arrow"><img src="rms-conlab/images/icon_link_arrow.svg" alt="icon"/></div> */}
          <ul className="swiper-wrapper">
            <li className={`swiper-slide ${obj.image}`}>
              <a href={`${obj.link}`} target="_blank">

                <h3>{obj.title1}<br className="onlyweb" />{obj.title2}</h3>
                <span style={{ display: 'flex', flexFlow: 'column' }}><p>{obj.description1}</p><p style={{ marginTop: 'unset' }}>{obj.description2}</p></span>
              </a>
            </li>
          </ul>
        </div>
      </SwiperSlide>
    );
    return (
      orgLink
    );
  }


  render() {
    const { faqList } = this.state;
    const {
      noticeList,
      openDetailModal,
      countUpData,
      // openLoginModal,
      // openModal,
      // modalText,
      // loginFail,
      // loginFailText,
      isAlert,
      openNotice,
      btnHover
    } = this.state;
    const { isLogin, citizenUser } = this.context;

    const fullContentTitle = countUpData[0].title;
    const fullContentValue = countUpData[0].value;
    const touristInformationTitle = countUpData[1].title;
    const touristInformationValue = countUpData[1].value;
    const imageTitle = countUpData[2].title;
    const imageValue = countUpData[2].value;
    const mediaTitle = countUpData[3].title;
    const mediaValue = countUpData[3].value;
    const fileTitle = countUpData[4].title;
    const fileValue = countUpData[4].value;

    const settings = {
      autoplay: true,
      dots: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplaySpeed: 5000,
      infinite: true,
    };

    return (
      <>
        <div id="contents" className="main">
          <section className="main_visual_wrap">
            <div className="center">
              <div className="main_visual">

                <Slider {...settings}>

                {/* <div className="slide_box">
                  <div onClick={() => window.open('/rms-conlab/announcement-detail?selectedPostId=e99d2085-e826-42bc-b410-1f1c843d6122&startingTime=0&registrationTime=1731386842978', '_blank')}>
                    <img src="/rms-conlab/images/banner_pc_241118.jpg" className="img pcImg" />
                    <img src="/rms-conlab/images/banner_mb_241118.png" className="img mImg" />
                  </div>
                </div> */}
                
                {/* <div className="slide_box">
                  <div onClick={() => window.open('https://api.visitkorea.or.kr/#/cmsNoticeDetail?no=197', '_blank')}>
                    <img src="/rms-conlab/images/banner_pc_241119.png" className="img pcImg" />
                    <img src="/rms-conlab/images/banner_mb_241119.png" className="img mImg" />
                  </div>
                </div> */}

                  <div className="slide_box slide_box1">
                    <div>
                      <div className="main_visual_inner">
                        <h2>
                          오늘의 콘랩
                          <img
                            className="icon"
                            src="/rms-conlab/images/icon_point.png"
                            alt="icon"
                          />
                          <br />
                          콘텐츠
                        </h2>
                        <div className="statistics_area">
                          <ul>
                            <li>
                              <h4>전체</h4>
                              {/* <h4>{fullContentTitle}</h4> */}
                              <CountUp
                                end={fullContentValue}
                                // suffix="건"
                                separator=","
                              />
                            </li>
                            <li>
                              <div className="statistics_info">
                                <h5>
                                  <div className="box">
                                    <img
                                      src="/rms-conlab/images/icon_info.png"
                                      alt="icon"
                                    />
                                    {touristInformationTitle}
                                  </div>
                                </h5>
                                <CountUp
                                  end={touristInformationValue}
                                  // suffix="건"
                                  separator=","
                                />
                              </div>
                              <div className="statistics_info">
                                <h5>
                                  <div className="box">
                                    <img
                                      src="/rms-conlab/images/icon_statistics_showcase_photo.png"
                                      alt="icon"
                                      style={{ filter: 'brightness(100)' }}
                                    />
                                    {imageTitle}
                                  </div>
                                </h5>
                                <CountUp
                                  end={imageValue}
                                  // suffix="건"
                                  separator=","
                                />
                              </div>
                            </li>
                            <li>
                              <div className="statistics_info">
                                <h5>
                                  <div className="box">
                                    <img
                                      src="/rms-conlab/images/icon_video.png"
                                      alt="icon"
                                    />
                                    {mediaTitle}
                                  </div>
                                </h5>
                                <CountUp
                                  end={mediaValue}
                                  // suffix="건"
                                  separator=","
                                />
                              </div>
                              <div className="statistics_info">
                                <h5>
                                  <div className="box">
                                    <img
                                      src="/rms-conlab/images/icon_file.png"
                                      alt="icon"
                                    />
                                    {fileTitle}
                                  </div>
                                </h5>
                                <CountUp
                                  end={fileValue}
                                  // suffix="건"
                                  separator=","
                                />
                                <span className="add_txt">
                                  <img
                                    src="/rms-conlab/images/icon_point_s.png"
                                    alt="icon"
                                    style={{ verticalAlign: "middle" }}
                                  />
                                  전일 기준
                                </span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <video autoPlay loop muted playsInline>
                        <source src="/rms-conlab/videos/main_visual_video.mov" type="video/mp4" />
                      </video>
                    </div>
                  </div>

                  {/* <div className="slide_box">
                    <div onClick={() => window.open('/rms-conlab/announcement-detail?selectedPostId=2c2ade02-4bda-41e3-ab1a-6d64406379cb&startingTime=0&registrationTime=1729583948836&searchType=title&searchValue=', '_blank')}>
                      <img src="/rms-conlab/images/main_banner383.jpg" className="img pcImg" />
                      <img src="/rms-conlab/images/main_banner383_mobile.png" className="img mImg" />
                    </div>
                  </div> */}

                  {/* <div className="slide_box">
                    <div onClick={() => window.open('https://www.culture.go.kr/digicon', '_blank')}>
                      <img src="/rms-conlab/images/main_img04.png" className="img pcImg" />
                      <img src="/rms-conlab/images/banner_1023.png" className="img mImg" />
                    </div>
                  </div> */}

                  <div className="slide_box">
                    <div>
                      <img src="/rms-conlab/images/pc_conlab_main01.png" className="img pcImg" />
                      <img src="/rms-conlab/images/m_conlab_main01.png" className="img mImg" />
                    </div>
                  </div>

                  <div className="slide_box">
                    <div>
                      <img src="/rms-conlab/images/pc_conlab_main02.png" className="img pcImg" />
                      <img src="/rms-conlab/images/m_conlab_main02.png" className="img mImg" />
                    </div>
                  </div>

                  <div className="slide_box">
                    <div>
                      <img src="/rms-conlab/images/pc_conlab_main03.png" className="img pcImg" />
                      <img src="/rms-conlab/images/m_conlab_main03.png" className="img mImg" />
                    </div>
                  </div>

                  {/* <div className="slide_box">
                    <div>
                      <a href="http://www.2024tourapi.com">
                        <img src="/rms-conlab/images/pc_conlab_main04.png" className="img pcImg" />
                        <img src="/rms-conlab/images/m_conlab_main04.png" className="img mImg" />
                      </a>
                    </div>
                  </div> */}

                </Slider>

              </div>

              <div className="main_login_box">
                <LoginPannel />
              </div>

            </div>
          </section>

          {/* <section className="slogan">
            <div className="center">
              <h3>
                <img src="/rms-conlab/images/slogan_Gather.png" alt="Gather" />
                관광 정보를 모아서,
              </h3>
              <h3>
                알차게 정리하고,
                <img src="/rms-conlab/images/slogan_Tag.png" alt="Tag" />
              </h3>
              <h3>
                <img src="/rms-conlab/images/slogan_Share.png" alt="Share" />
                널리 알려요.
              </h3>
            </div>
          </section> */}

          <section className="nw_wcid">
            <div className="center">
              <h3 style={{ textAlign: 'center' }}>
                콘랩의 다양한 기능을 경험해보세요
              </h3>
              <ul>
                <button onClick={() => this.routeSearchK()} style={btnHover[0] ? { backgroundColor: '#E9EEFC' } : { backgroundColor: '#F0F0F5' }} onMouseEnter={() => this.mouseHoverBtn(0)} onMouseLeave={this.mouseHoverOutBtn}>
                  <li>
                    <span>관광콘텐츠 검색/조회</span>
                    <br />
                    <br />
                    <p style={{ color: '#5E5E61' }}>국문·다국어 관광지 정보, 이미지 및 동영상 콘텐츠 등 다양한 관광콘텐츠를 찾아볼 수 있습니다.</p>
                  </li>
                </button>
                <button onClick={() => this.routeTourismRecommend()} style={btnHover[1] ? { backgroundColor: '#E9EEFC' } : { backgroundColor: '#F0F0F5' }} onMouseEnter={() => this.mouseHoverBtn(1)} onMouseLeave={this.mouseHoverOutBtn}>
                  <li>
                    <span>관광콘텐츠 사용</span>
                    <br />
                    <br />
                    <p style={{ color: '#5E5E61' }}>관광콘텐츠를 다운로드하여 공공누리 유형에 따라 자유롭게 사용할 수 있습니다.</p>
                  </li>
                </button>
                <button onClick={() => this.routeWorkspace()} style={btnHover[2] ? { backgroundColor: '#E9EEFC' } : { backgroundColor: '#F0F0F5' }} onMouseEnter={() => this.mouseHoverBtn(2)} onMouseLeave={this.mouseHoverOutBtn}>
                  <li>
                    <span>나의 관광콘텐츠 관리</span>
                    <br />
                    <br />
                    <p style={{ color: '#5E5E61' }}>단체별/개인별 관광콘텐츠 관리공간에서 관광콘텐츠를 등록하고 관리 할 수 있습니다.</p>
                  </li>
                </button>
                <button onClick={() => this.routeDev()} style={btnHover[3] ? { backgroundColor: '#E9EEFC' } : { backgroundColor: '#F0F0F5' }} onMouseEnter={() => this.mouseHoverBtn(3)} onMouseLeave={this.mouseHoverOutBtn}>
                  <li>
                    <span>개발자센터 활용</span>
                    <br />
                    <br />
                    <p style={{ color: '#5E5E61' }}>한국관광콘텐츠랩에서 제공하는 API와 UI 컴포넌트를 나의 서비스 채널(웹/앱)에서 활용할 수 있습니다.</p>
                  </li>
                </button>
              </ul>
            </div>
          </section>

          <section className="recommend">
            <div className="center">
              <h3>
                콘랩이 추천하는
                <br />
                <span><a className="tour_link" href="/rms-conlab/tourism-recommend" style={{ color: '#2658e3' }}>관광지</a></span>를 확인해보세요
                <img
                  src="/rms-conlab/images/icon_tit_arrow.svg"
                  alt="icon_tit_arrow"
                />
              </h3>
              {this.renderListBanner()}
            </div>
          </section>

          <section className="board_preview">
            <div className="center">
              <div className="bp_list">
                <div className="board_box">
                  <h3 className="tit">
                    공지사항
                    <a href="/rms-conlab/announcement">
                      더보기{" "}
                      <img
                        src="/rms-conlab/images/icon_tit_arrow.svg"
                        alt="icon_tit_arrow"
                      />
                    </a>
                  </h3>
                  <ul>
                    {noticeList.map((notice: any, index) => (
                      <li
                        key={notice.id}
                        onClick={() => this.showNoticeDetail(notice.id, notice.startingTime, notice.registrationTime)}
                        draggable={true}
                      >
                        <a>
                          <h4 style={{ userSelect: 'text' }}>{notice.title}</h4>
                          <span>
                            {moment(notice.registrationTime).format(
                              "YYYY.MM.DD"
                            )}
                          </span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="board_box">
                  <h3 className="tit">
                    자주묻는 질문
                    <a href="/rms-conlab/questions">
                      더보기{" "}
                      <img
                        src="/rms-conlab/images/icon_tit_arrow.svg"
                        alt="icon_tit_arrow"
                      />
                    </a>
                  </h3>
                  <ul>
                    {faqList.map((faq: any) => (
                      <li
                        key={faq.id}
                        onClick={() => this.showFaqDetail()}
                        draggable={true}
                      >
                        <a>
                          <h4 style={{ userSelect: 'text' }}>{faq.title}</h4>
                          <span className="icon">
                            <img
                              src="/rms-conlab/images/icon_board_link.svg"
                              alt="icon_board_link"
                            />
                          </span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="home_links">
                <div className="links">
                  <a
                    onClick={() => this.routeWorkspace()}
                  >
                    <h3>
                      워크스페이스
                      <img
                        src="/rms-conlab/images/icon_link_arrow.svg"
                        alt="icon"
                      />
                    </h3>
                    <p>
                      콘텐츠 등록부터 다운로드까지! <br />
                      콘텐츠랩 워크스페이스를 경험해보세요.
                    </p>
                    <br></br>
                    <Chip label="PC전용" style={{ fontWeight: 'bold' }} />
                  </a>

                  <a onClick={() => this.routeDev()}>
                    <div>
                      <h3>
                        개발자 센터
                        <img
                          src="/rms-conlab/images/icon_link_arrow.svg"
                          alt="icon"
                        />
                      </h3>
                      <p>
                        API, UI 컴포넌트 등 다양한 개발 요소를 <br />
                        개발자센터에서 경험해 보세요.
                      </p>
                    </div>
                  </a>
                </div>
                {this.renderOrganization()}
              </div>
            </div>
          </section>
        </div>

        {/* {this.state.open && this.state.open === "open" && (
          <Modal close={this.close} login={this.onClickTopLoginModalOpen} />
        )} */}

        {isAlert && <Alert message={this.state.alertMsg} eventCloseModal={this.closeAlert} />}
         {/*{openNotice && <NoticeModalView evnetCloseNotice={this.closeNotice}/>}*/}

        {openDetailModal && openDetailModal === "detail" && (
          <ModalView
            onClickThemeCloseModal={this.onClickThemeCloseModal}
            onCloseModal={this.onClickCloseModal}
            modalType={this.state.modalType}
            themeDetailObject={this.state.themeDetailObject}
            themeDetailList={this.state.themeDetailList}
            recommendThemeObject={this.state.recommendThemeObject}
            imageDetailBase={this.state.imageDetailBase}
            categoryPath={this.state.categoryPath}
            categoryAddress={this.state.categoryAddress}
            categoryId={this.state.categoryId}
          />
        )}
      </>
    );
  }
}

export default MainPage;

